<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p>开充卡业绩 - 会员卡</p>
      <!-- top -->
      <div class="top">
        <div class="left">
          <div class="select">
            <el-date-picker
              v-model="timeQuantum"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
            >
            </el-date-picker>
            <el-radio-group v-model="month" size="mini">
              <el-radio-button label="今日"></el-radio-button>
              <el-radio-button label="昨天"></el-radio-button>
              <el-radio-button label="本月"></el-radio-button>
              <el-radio-button label="上月"></el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- end top -->
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#333333' }"
        border
        size="small"
      >
        <el-table-column prop="date" label="员工名称"> </el-table-column>
        <el-table-column prop="date" label="开卡数量"> </el-table-column>
        <el-table-column prop="date" label="开卡业绩"> </el-table-column>
        <el-table-column prop="date" label="开卡充值实收">
        </el-table-column> </el-table
      ><!-- end table -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="400"
        background
      >
      </el-pagination>
      <p class="color">
        总计 - 开卡数量: 0张， 开卡业绩: 0元， 开卡充值实收: 0元。
      </p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      timeQuantum: "",
      month: "今日",
      currentPage4: 1,
    };
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>

<style lang="less" scoped>
p {
  margin: 0;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #c0ccda;
  .left {
    display: flex;
    align-items: center;
    .select {
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 220px !important;
      }
      .el-radio-group {
        margin: 0 10px;
      }
    }
    span {
      font-size: 16px;
      margin-right: 10px;
    }
    i {
      font-style: normal;
    }
  }
}
.el-table {
  margin-top: 20px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.color {
  color: #409eff;
}
</style>

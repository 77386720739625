<template>
	<div>
		<!-- top -->
		<div class="top">
			<div class="left">
				<div class="select">
					<el-date-picker
						v-model="timeQuantum"
						type="daterange"
						range-separator="-"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						size="mini">
					</el-date-picker>
				  <el-radio-group v-model="month" size="mini">
						<el-radio-button label="今日"></el-radio-button>
						<el-radio-button label="昨天"></el-radio-button>
						<el-radio-button label="本月"></el-radio-button>
						<el-radio-button label="上月"></el-radio-button>
					</el-radio-group>
				</div>
			</div>
			<div class="right">
				<el-button size="mini" type="primary">保存为常用</el-button>
				<el-button size="mini" type="primary">更多</el-button>
			</div>
		</div><!-- end top --> 
		<el-row>
		  <el-col :span="4">
				<div>
					<p class="color">开充值实收(元)</p>
					<p class="size">
						<span>7,848</span>
						<span>
							<i class="el-icon-caret-bottom"></i>
							<i>0%</i>
						</span>
					</p>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">新增卡金(元)</p>
						<p class="size">
							<span>7,848</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>0%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">新增赠金(元)</p>
						<p class="size">
							<span>828</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-54.15%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		  <el-col :span="4">
				<div>
					<div>
						<p class="color">耗卡(元)</p>
						<p class="size">
							<span>8,181</span>
							<span>
								<i class="el-icon-caret-bottom"></i>
								<i>-66.94%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div>
					<div>
						<p class="color">积分兑换(元)</p>
						<p class="size">
							<span>0 (0分)</span>
							<span>
								<i>0%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div>
					<div>
						<p class="color">耗赠金(元)</p>
						<p class="size">
							<span>883</span>
							<span>
								<i>0%</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
			<el-col :span="4">
				<div>
					<div>
						<p class="color">门店最新卡余额/赠金金额(元)</p>
						<p class="size">
							<span>95,638</span>
							<span>
								<i>/ 3,898</i>
							</span>
						</p>
					</div>
				</div>
			</el-col>
		</el-row>
	  <!-- top -->
	  <div class="top">
	  	<div class="left">
	  		 <span>开卡分析</span>
				 <el-popover
				 	placement="bottom"
				 	width="300"
				 	trigger="click">
				 	<div class="look" slot="reference">
				 		<el-button icon="el-icon-s-grid" size="mini"></el-button>
				 	</div>
				 </el-popover>
	  	</div>
	  </div><!-- end top --> 
		<!-- table -->
		<el-table
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{color:'#475669'}"
			show-summary
			size="small">
			<el-table-column
				prop="date"
				label="会员卡"
				width="150"
				fixed>
				<template slot-scope="scope">
				   <div @click="handleEdit(scope.$index, scope.row)" class="color">{{ scope.row.date }}</div>
				</template>
			</el-table-column>
			<el-table-column label="新开" align="center">
				<el-table-column
					prop="province"
					label="新开(张)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="city"
					label="初充卡金(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="address"
					label="工本费(元)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="赠送金(元)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="实收(元)"
					width="100">
				</el-table-column>
			</el-table-column>
			<el-table-column label="充值／升级" align="center">
				<el-table-column
					prop="zip"
					label="充值(张)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="升级(张)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="再充卡金(元)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="赠送金(元)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="实收(元)"
					width="100">
				</el-table-column>
			</el-table-column>
			<el-table-column 
			  label="开充值实收(元)" 
				prop="province"
				width="120">
			</el-table-column>
			<el-table-column label="卡金统计" align="center">
				<el-table-column
					prop="zip"
					label="新增(元)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="消耗(元)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="净值(元)"
					width="100">
				</el-table-column>
			</el-table-column>
			<el-table-column label="赠金统计" align="center">
				<el-table-column
					prop="zip"
					label="新增(元)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="消耗(元)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="净值(元)"
					width="100">
				</el-table-column>
			</el-table-column>
			<el-table-column label="喜好度统计" align="center">
				<el-table-column
					prop="zip"
					label="用卡人次(次)"
					width="120">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="耗卡率(%)"
					width="100">
				</el-table-column>
				<el-table-column
					prop="zip"
					label="受欢迎率(%)"
					width="120">
				</el-table-column>
			</el-table-column>
		</el-table><!-- end table -->
		<!-- top -->
		<div class="top">
			<div class="left">
				 <span>耗分析</span>
				 <el-popover
				 	placement="bottom"
				 	width="300"
				 	trigger="click">
				 	<div class="look" slot="reference">
				 		<el-button icon="el-icon-s-grid" size="mini"></el-button>
				 	</div>
				 </el-popover>
			</div>
		</div><!-- end top --> 
		<el-table
			:data="tableData"
			style="width: 100%"
			:header-cell-style="{color:'#475669'}"
			show-summary
			size="small">
			<el-table-column
				prop="date"
				label="会员卡"
				width="200"
				fixed>
			</el-table-column>
			<el-table-column label="累计耗卡分析（不受上方日期影响）" align="center">
				<el-table-column
					prop="province"
					label="最新持卡(人)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="持卡占比(%)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="累计充值卡金(元)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="累计赠送金(元)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="累计耗卡(元)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="累计耗赠金(元)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="过期卡金(元)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="有效卡金额(元)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="有效赠金余额(元)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="卡余额占比(%)">
				</el-table-column>
				<el-table-column
					prop="province"
					label="耗卡率(%)">
				</el-table-column>
		  </el-table-column>
		</el-table><!-- end table -->
		<div style="height: 20px;"></div>
		<LookDialog ref="LookDialog"></LookDialog>
	</div>
</template>

<script>
	import LookDialog from './components/look-dialog.vue'
	export default {
		components:{  LookDialog },
		data() {
			return {
				timeQuantum: '',
			  month: '今日',
				tableData: [{
					id: '全店',
					date: '会员卡',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '银卡',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				},
				{
					id: '全店',
					date: '还款',
					name: '0',
					province: '0',
					city: '0',
					address: '0',
					zip: 0
				}]
			}
		},
		methods:{
			handleEdit(index, row){
				this.$refs.LookDialog.dialogVisible = true
			}
		}
	}
</script>

<style lang="less" scoped>
.top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
	.left{
		display: flex;
		align-items: center;
		.select{
			display: flex;
			align-items: center;
			.el-input__inner{
				width: 220px !important;
			}
			.el-radio-group{
				margin: 0 10px;
			}
		}
		span{
			font-size: 16px;
		}
		i{
			font-style: normal;
		}
	}
	.right{
		display: flex;
		align-items: center;
		span{
			margin-left: 30px;
			color: #475669;
		}
	}
	.el-button{
		margin-left: 10px;
	}
	.prompt{
		margin-left: 20px;
	}
	.center{
		display: flex;
		align-items: center;
	}
}
.el-row{
	margin: 30px 0;
	padding: 20px 0;
	border: 1px solid #d3dce6;
	border-radius: 5px;
	.el-col{
		border-right: 1px solid #d3dce6;
		padding: 0 20px;
		height: 92px;
		width: 14%;
		p{
			margin: 0;
			line-height: 2;
		}
		.color{
			color: #8492a6;
		}
		.size>span:first-child{
			font-size: 18px;
		}
		.size>span:last-child{
			color: #30c27c;
			margin-left: 20px;
			i{
				margin-right: 10px;
			}
		}
	}
	.el-col:last-child{
		border-right: none;
	}
}

.el-table {
	font-size: 14px;
	.color{
	  color: #20a0ff;
	  cursor: pointer;
	}
}
</style>
